<template>
  <div>
    <modal
      className="pb-5 pt-10 px-10 rounded w-full sm:px-12 md:w-443px lg:w-443px"
      ref="modal"
      @close="closeBalanceClearModal"
    >
      <template v-if="track == 'clearbalance'">
        <h4
          class="mb-6 text-xl text-gray-700"
        >
          Clear Balance
        </h4>
        <div
          class="bg-ash-600 -mx-10 py-5 px-10 sm:-mx-12 sm:px-12"
        >
          <div class="flex justify-between">
            <p class="text-14 font-bold text-gray-700 leading-6 text-right">
              Available Credit
            </p>
            <p class="text-14 font-bold text-gray-700 text-left">
              {{ availableCredit | currency }}
            </p>
          </div>
        </div>

        <div>
          <div class="flex justify-between pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Opening Balance
            </p>
            <p class="text-12 font-normal text-red-500 text-left">
              {{ openingBalance }}
            </p>
          </div>
          <div class="flex justify-between pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Interest on Amount Spent
            </p>
            <p class="text-12 font-normal text-red-500 text-left">
              {{ interest }}
            </p>
          </div>

          <div class="flex justify-between pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Delayed Payment Charge
            </p>
            <p class="text-12 font-normal text-gray-700 text-left">
              {{ defaultCharge }}
            </p>
          </div>

          <div class="flex justify-between pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">
              Deferred Plan Charge
            </p>
            <p class="text-12 font-normal text-gray-700 text-left">
              {{ deferredPlan }}
            </p>
          </div>

          <!-- <div class="flex justify-between pt-6">
              <p class="text-12 text-gray-700 leading-6 text-right">
                Processing Fee
              </p>
              <p class="text-12 font-normal text-gray-700 text-left">
                {{ processingFee }}
              </p>
            </div> -->

          <div class="flex justify-between pt-6">
            <p class="text-12 text-gray-700 leading-6 text-right">Amount Spent</p>
            <p class="text-12 font-normal text-gray-700 text-left">
              {{ amountSpent }}
            </p>
          </div>

          <div>
            <svg
              width="363"
              height="1"
              class="mt-6"
              viewBox="0 0 363 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                opacity="0.06"
                x1="-4.37114e-08"
                y1="0.5"
                x2="363"
                y2="0.499968"
                stroke="black"
                stroke-dasharray="5 5"
              />
            </svg>
          </div>

          <div class="flex justify-between pt-4">
            <p
              class="text-12 font-semibold mt-1 text-gray-700 leading-6 text-right"
            >
              Total due
            </p>
            <p class="text-20 font-bold text-blue-500 text-left">
              {{ totalBalance | currency }}
            </p>
          </div>

          <div class="flex justify-center mt-12 mb-6">
            <div class="flex flex-row gap-4 w-full">
              <button
                class="btn btn-gray-outline btn-md w-full"
                @click.prevent="downloadStatement"
                :disabled="downloading"
              >
                <sm-loader v-if="downloading" />
                <template v-else>
                  <div class="inline-flex text-xl">
                    <ion-icon name="download-outline"></ion-icon>
                  </div>
                  <span class="ml-2">Statement</span>
                </template>
              </button>
              <button
                class="btn btn-blue btn-md w-full"
                @click.prevent="track = 'repaymentcard'"
              >
                Pay Now
              </button>
            </div>
          </div>

          <div
            class="flex justify-center bg-ash-600 h-full -mx-6 sm:mx-0"
            v-if="myWalletBalance && cardRequested && myWalletBalance > 0"
          >
            <div class="flex flex-col">
              <span class="text-gray-700 text-13 text-center font-semibold pt-5">
                Credits: {{ myWalletBalance | currency }}
              </span>
              <button
                class="btn btn-blue px-8 mt-3 mb-4 font-normal text-xs text-white"
                @click="redeemWallet"
              >
                Redeem
              </button>
            </div>
          </div>
        </div>
      </template>

      <template v-if="track == 'repaymentcard'">
        <h4 class="text-xl xl:text-2xl font-bold mb-12 text-left text-gray-700">
          Pay With
        </h4>
        <div class="w-full">
          <template v-if="getFormError(repyamentError)">
            <div class="alert alert-red-soft -mt-6 mb-10">
              <span class="alert-icon">!</span>
              <span class="text-xs font-normal">{{
                getFormError(repyamentError)
              }}</span>
            </div>
          </template>
          <template v-if="transferError">
            <div class="alert alert-red-soft -mt-6 mb-10">
              <span class="alert-icon">!</span>
              <span class="text-xs font-normal">{{ transferError }}</span>
            </div>
          </template>
          <div class="w-full flex items-center justify-between -mt-4 pt-1 pb-2">
            <span class="font-bold">Debit Card</span>
            <button
              @click="clearTotalBalance"
              class="text-blue-500 bg-transparent"
            >
              + Add a Card
            </button>
          </div>

          <div
            class="max-h-full overflow-y-scroll"
            :class="{
              'h-40': repaymentCards.length === 2,
              'h-20': repaymentCards.length === 1,
              'h-64': repaymentCards.length > 3,
            }"
          >
            <div
              class="w-full flex flex-col cursor-pointer"
              v-for="(card, i) in repaymentCards"
              :key="i"
              @click="setCardId(i)"
            >
              <div class="w-full flex items-center bg-gray-200 px-6 py-2 rounded-sm my-2">
                <div class="w-2/12">
                  <img
                    :src="svg.visa"
                    alt=""
                    v-if="repaymentCardType(card.card_type) == 'visa'"
                  />
                  <img
                    :src="svg.master"
                    alt=""
                    v-else-if="repaymentCardType(card.card_type) == 'master'"
                  />
                  <img :src="svg.master" alt="" v-else />
                </div>

                <div class="w-8/12 sm:w-4/12 flex mt-1">
                  <div
                    class="w-full flex justify-start items-center font-extrabold text-gray-800 text-14 pb-3"
                  >
                    . . . .
                    <span class="ml-2 mt-2 font-normal">{{ card.last4 }}</span>
                  </div>
                </div>
                <div class="w-2/12 sm:w-6/12 flex justify-end">
                  <p
                    v-if="cardId != i || clearBalanceMethod != 'card'"
                    class="bg-blue-200 h-24px rounded-full w-24px"
                  ></p>
                  <div class="flex justify-center items-center dam" v-else>
                    <vector :src="svg.check" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p class="text-10 font-normal text-blue-500 text-left">
            A processing fee of {{ processingFee }} applies.
          </p>

          <div class="w-full flex items-center mt-10 pt-1 pb-2">
            <span class="font-bold">Bank Transfer</span>
          </div>
          <div class="pt-2 cursor-pointer mt-4 pb-2">
            <div
              class="pt-2 cursor-pointer mb-4 -mt-4 pb-2 w-full"
              @click.prevent="setTransferCollectionProvider('korapay')"
            >
              <korapay
                @confirmTransfer="confirmTransfer"
                :amount="totalTransferAmount"
                :payment-reference="payReference"
                :key="componentKey"
                :email="email"
                ref="korapay"
              >
                <div
                  class="w-full flex items-center justify-between bg-gray-200 px-6 py-4 rounded-sm"
                >
                  <img
                    :src="svg.korapayLogo"
                    class="w-20"
                    height="40"
                    alt="korapay"
                  />
                  <div class="w-2/12 sm:w-6/12 flex justify-end">
                    <div
                      class="flex justify-center items-center"
                      v-if="
                        transferCollectionProvider == 'korapay' &&
                        clearBalanceMethod == 'transfer'
                      "
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="24" height="24" rx="12" fill="#274FED" />
                        <path
                          d="M17 9L10.125 16L7 12.8182"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <p class="bg-blue-200 h-24px rounded-full w-24px" v-else></p>
                  </div>
                </div>
                <p class="text-10 font-normal text-blue-500 mt-2 text-left">
                  A processing fee of ₦{{ korapayTransferFee | currency }}
                  applies.
                </p>
              </korapay>
            </div>
          </div>

          <div class="flex flex-wrap gap-4 justify-center mb-4">
            <button
              type="button"
              class="btn btn-gray-outline btn-md"
              :disabled="clearing"
              @click.prevent="track = 'clearbalance'"
            >
              <span class="text-ash-800">Back</span>
            </button>
            <button
              type="button"
              class="btn btn-blue btn-md"
              :disabled="clearing"
              @click.prevent="continueToRepayment"
            >
              <sm-loader-white v-if="clearing" />
              <span v-else>Continue</span>
            </button>
          </div>

          <paystack
            :amount="total"
            class="hidden"
            :callback="completeClearing"
            ref="clearButton"
          >
            <template v-if="clearing">Verifying...</template>
            <template v-else> Finish </template>
          </paystack>
        </div>
      </template>
    </modal>

    <modal
      className="w-11/12 sm:w-443px md:w-443px xl:w-443px rounded text-center pt-24"
      ref="repaymentSuccessModal"
    >
      <img
        :src="
          repaymentStatus
            ? assets.icons.lg.checkmark
            : assets.icons.lg.exclamation
        "
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />
      <div class="text-lg font-bold mb-4">
        {{ repaymentStatus ? "Success!" : "Alert!" }}
      </div>
      <div class="text-16 mb-10" v-html="paymentMessage"></div>
      <div class="flex justify-center">
        <button
          type="button"
          class="button bg-blue-500 hover:bg-blue-600 px-12 text-white mt-2"
          @click.prevent="closeRepaymentSuccess"
        >
          Close
        </button>
      </div>
    </modal>

    <modal22
      className="w-11/12 sm:w-443px md:w-443px xl:w-443px rounded text-center pt-24 bg-white"
      ref="waitingModal"
    >
      <img
        :src="assets.icons.lg.exclamation"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />
      <div class="text-lg font-bold mb-4">
        Alert!
      </div>
      <div class="text-16 mb-10">
        Please Hold on while we confirm the transaction
      </div>
    </modal22>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import axios from "axios";

export default {
  name: "CreditCardBalance",
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cardId: 0,
      clearBalanceMethod: "card",
      clearing: false,
      downloading: false,
      paymentMessage: "Your Repayment was successful.",
      repyamentError: {},
      repaymentStatus: false,
      svg: {
        visa: require("@/assets/visa-dark.svg"),
        master: require("@/assets/mastercard.svg"),
        check: require("@/assets/blue-check.svg"),
        deal: require("@/assets/deals.svg"),
        korapayLogo: require("@/assets/korapay_logo.svg"),
      },
      track: "clearbalance",
      transferCollectionProvider: "",
      transferError: "",
      componentKey: 0,
    };
  },
  computed: {
    amountSpent() {
      return this.formatAmount(
        this.user?.personal_statement?.statement?.agg_balance
      );
    },
    availableCredit() {
      return this.card?.available_credit;
    },
    defaultCharge() {
      return this.formatAmount(
        this.user?.personal_statement?.statement?.fees_and_charges
      );
    },
    deferredPlan() {
      return this.formatAmount(
        this.user?.personal_statement?.statement?.deferred_plan_amount
      );
    },
    email() {
      return this.user?.email;
    },
    interest() {
      return this.formatAmount(
        this.user?.personal_statement?.statement?.interest
      );
    },
    korapayTransferFee() {
      return (
        this.$store.state.session?.configuration?.korapay_transfer_fee || 197.25
      );
    },
    myWalletBalance() {
      return this.rewardWallet?.data?.reward_wallet?.balance;
    },
    openingBalance() {
      return this.formatAmount(
        this.user?.personal_statement?.statement?.opening_balance
      );
    },
    paymentProcessingFee() {
      return this.user.personal_statement?.processing_fee;
    },
    processingFee() {
      return this.formatAmount(this.paymentProcessingFee);
    },
    repaymentCards() {
      return this.resources.repaymentCards?.data;
    },
    total() {
      const sum = this.totalBalance + this.paymentProcessingFee;
      return sum;
    },
    totalBalance() {
      return this.user.personal_statement?.statement?.total_outstanding;
    },
    totalTransferAmount() {
      return (
        parseFloat(this.totalBalance) + parseFloat(this.korapayTransferFee)
      );
    },
    payReference() {
      return `PCA-${this.user.personal_statement?.statement?.account_id}`
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    closeBalanceClearModal() {
      this.transferError = "";
      this.repyamentError = {};
    },
    closeRepaymentSuccess() {
      this.$refs.repaymentSuccessModal.close();
      this.getPersonalCards();
    },
    continueToRepayment() {
      if (this.clearBalanceMethod === "transfer") {
        this.componentKey += 1;
        this.$nextTick(() => this.startTransfer());
        if(this.totalTransferAmount <= parseFloat(this.korapayTransferFee)) {
          this.repyamentError = {};
          this.transferError = "You are trying to clear ₦0";
          return;
        }
        // this.startTransfer();
        return;
      }
      this.proceedToRepayment();
    },
    getPersonalCards() {
      this.$emit('get-personal-cards');
    },
    getRepaymentCardDetails() {
      return this.repaymentCards[this.cardId];
    },
    open() {
      this.$refs.modal.open();
    },
    repaymentCardType(cardType) {
      return cardType.includes("visa")
        ? "visa"
        : cardType.includes("verve")
        ? "verve"
        : "master";
    },
    setCardId(index) {
      this.cardId = index;
      this.clearBalanceMethod = "card";
    },
    setTransferCollectionProvider(provider) {
      this.clearBalanceMethod = "transfer";
      this.transferCollectionProvider = provider;
    },
    startTransfer() {
      this.$refs.korapay.payKorapay();
      this.$refs.modal.close();
    },
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    async addRepaymentCard(reference) {
      await this.$post({
        url: `${this.$baseurl}/staff/card/repayment/setup`,
        data: {
          user_id: this.user.id,
          reference,
        },
        headers: this.headers,
        success: () => {
          this.paymentMessage = "Payment was successful";
          this.$refs.modal.close();
          this.$refs.repaymentSuccessModal.open();
        },
        error: (error) => {
          this.repyamentError.error = error;
        },
      });
      await this.$root.loadSession();
    },
    async clearTotalBalance() {
      this.clearing = true;
      this.$refs.clearButton.$el.click();
      setTimeout(() => {
        this.clearing = false;
      }, 800);
    },
    async completeClearing(data) {
      var { reference } = data;

      this.clearing = true;

      await this.$post({
        url: `${this.$baseurl}/repayment/personal`,
        data: {
          payment_method: "paystack",
          reference,
        },
        headers: this.headers,
        success: () => {
          this.addRepaymentCard(reference);
          this.repaymentStatus = true;
        },
        error: (error) => {
          this.repyamentError.error = error;
        },
      });

      this.clearing = false;
    },
    async confirmTransaction(transaction) {
      await this.$post({
        url: `${this.$baseurl}/personal/debit-card/top-up`,
        data: {
          reference: transaction.transactionReference,
        },
        headers: this.headers,
        success: () => {
          this.$refs.topUpModal.close();
          this.getPersonalCards();
          this.$refs.successModal.open();
        },
        error: () => {
          this.topUpError = "Top up failed";
        },
      });
    },
    async confirmTransfer(transaction) {
      this.$refs.waitingModal?.open();
      await this.sendRequest("personal.transfers.clearBalanceTransfer", {
        data: {
          payment_method: "korapay",
          reference: transaction.reference,
          amount: transaction.amount,
          account_id: this.user?.personal_account?.id,
        },
        success: async (response) => {
          this.$refs.modal.close();
          this.repaymentStatus = true;
          this.getPersonalCards();
          if (response.data.data.transfer_status == "pending") {
            this.paymentMessage = "Trasfer is Pending, please hold on";
            return;
          }
          this.paymentMessage = response.data.message;
        },
        error: (error) => {
          this.repyamentError.error = error;
        },
      });
      this.$refs.waitingModal?.close();
    },
    async downloadStatement() {
      this.downloading = true;
      await axios
        .get(`${this.$baseurl}/personal/statement/download`, {
          responseType: "blob",
          headers: this.headers,
        })
        .then((res) => {
          const pdfBlob = new Blob([res.data], {
            type: "application/pdf",
          });
          saveAs(pdfBlob, "statement.pdf");
          this.downloading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.downloading = false;
    },
    async proceedToRepayment() {
      let repaymentId;
      let showRepaymentSuccessModal = true;
      this.clearing = true;
      await this.$post({
        url: `${this.$baseurl}/repayment/personal`,
        data: {
          payment_method: "repayment_card",
          authorization_code: this.getRepaymentCardDetails()[
            "authorization_code"
          ],
        },
        headers: this.headers,
        success: (res) => {
          this.$refs.modal.close();
          this.paymentMessage = res.data.message;
          repaymentId = res.data.data.id;
          this.$refs.repaymentSuccessModal.open();
        },
        error: (error) => {
          this.transferError = "";
          this.repyamentError.error = error;
          showRepaymentSuccessModal = false;
        },
      });
      this.clearing = false;
      if (showRepaymentSuccessModal) {
        await this.timeout(62000);
        await this.showRepaymentSuccess(repaymentId);
        await this.$root.loadSession();
      }
    },
  },
};
</script>
